<template>
  <div id="admin-dashboard">
    <!-- Annoucements -->
    <annoucementsDashbordComponent></annoucementsDashbordComponent>
    <!-- Annoucements -->
    <v-row class>
      <v-col cols="12" sm="12">
        <h2 class="main-color dashboard-title">{{ $t("Dashboard") }}</h2>
      </v-col>
    </v-row>
    <!-- Kids Cards -->
    <v-row class="kids">
      <v-col
        cols="6"
        sm="3"
        class="py-0 mb-4"
        v-for="(st, index) in studentInfo"
        :key="index"
      >
        <v-row
          class="kid"
          :class="st.active == true ? 'active' : ''"
          @click="MakeHimActive(index)"
        >
          <v-col cols="4">
            <img
              :src="st.logo"
              v-if="st.logo"
              alt="student image"
              class="student-image"
            />
            <img
              v-else
              src="../../assets/avatar.png"
              class="student-image"
              alt=""
            />
          </v-col>
          <v-col cols="8">
            <p class="student-name">{{ st.name }}</p>
          </v-col>
          <v-col cols="6" class="py-0 px-0" v-if="st.grade">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p class="student-grade mb-1" v-bind="attrs" v-on="on">
                  {{ splitString(st.grade) }}
                </p>
              </template>
              <span>{{ st.grade }}</span>
            </v-tooltip>
          </v-col>

          <v-col cols="6" class="py-0" v-if="st.class">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="text-right student-class mb-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ splitString(st.class) }}
                </p>
              </template>
              <span>{{ st.class }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Invoice -->
    <v-row>
      <v-col cols="12" sm="12">
        <!-- iNVOICES -->
        <div class="cart-sec" style="overflow-x: scroll">
          <!-- v-if="invoicestable.permissions.show" -->
          <v-row>
            <!-- Title -->
            <v-col cols="4">
              <h3 class="title">
                {{ $t("Due Fees (Total SAR") }} {{ totalPayment }})
              </h3>
            </v-col>

            <!-- search input -->
            <v-col cols="5">
              <label class="search" for="inpt_search">
                <input
                  id="inpt_search"
                  class="input_search"
                  @focus="inputFocus"
                  @blur="inputBlur"
                  type="text"
                  v-model="search"
                  :title="$t('Search')"
                />
              </label>
            </v-col>
            <!-- fillter  -->
            <v-col cols="3" class="text-right">
              <!-- Filter -->
              <span :title="$t('Filter')">
                <v-menu
                  v-model="filterMenuInvoicess"
                  offset-y
                  :close-on-content-click="false"
                  :nudge-width="300"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <!-- <v-icon v-on="on" class="mr-2" big>
                        filter
                        </v-icon>-->
                    <i
                      v-on="on"
                      class="fas fa-filter main-color fa-lg"
                      :title="$t('Filter')"
                    ></i>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-autocomplete
                        v-model="filter.status"
                        :items="statusItems"
                        item-text="name"
                        item-value="value"
                        label="Status"
                        solo
                        multiple
                      ></v-autocomplete>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <v-data-table
                  :headers="invoicestable.headers"
                  :items="invoices"
                  class="elevation-1 table-striped"
                  :items-per-page="10"
                  :show-select="false"
                  hide-default-footer
                >
                  <template v-slot:item.payment_status="{ item }">
                    <span v-if="item.payment_status == 1" style="color: green">
                      {{ "Paid" }}
                    </span>
                    <span
                      v-else-if="item.payment_status == 2"
                      style="color: red"
                    >
                      {{ "PartialyPaid" }}
                    </span>
                    <span v-else style="color: red">{{ "Unpaid" }}</span>
                  </template>
                  <!-- payment_status -->
                  <template v-slot:item.actions="{ item }">
                    <v-icon @click="printInvoice(item.id)" :title="$t('Print')"
                      >print</v-icon
                    >
                    <v-icon
                      v-if="!item.payment_status || item.payment_status == 2"
                      @click="PayInvoice(item)"
                      >credit_card</v-icon
                    >
                  </template>
                </v-data-table>
                <div class="text-center pt-2" v-can="table.permissions.list">
                  <v-pagination
                    v-if="length > 0 && totalItems > 0"
                    v-model="pageInvoice"
                    :length="length"
                    :circle="circle"
                    :page="pageInvoice"
                    :total-visible="totalVisible"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                  ></v-pagination>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <div class="cart-sec exams-table">
          <!-- all exam results for student -->
          <DataTableComponent
            :table="table"
            :selectedChild="selectedStudent_id"
            v-if="childs.length > 0"
          ></DataTableComponent>
          <v-alert class="info_alert text-center" v-if="childs.length == 0">{{
            $t("There are no children")
          }}</v-alert>
        </div>
      </v-col>
    </v-row>

    <!-- assignment component -->
    <v-row>
      <v-col cols="12" sm="12">
        <assignmentsOnParentDB
          :selectedStudent_id="selectedStudent_id"
        ></assignmentsOnParentDB>
      </v-col>
    </v-row>
    <!-- Schedule -->
    <v-row>
      <v-col cols="12" sm="12" md="12" style="padding: 0; margin: 0">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="cart-sec">
              <v-row>
                <v-col cols="9">
                  <h3 class="title" v-if="currentAppLocale != 'ar'">
                    {{ $t("My") }}
                    {{ childs.length > 1 ? "Children" : "Child" }}
                    {{ $t("Schedule") }}
                  </h3>
                  <h3 class="title" v-if="currentAppLocale == 'ar'">
                    {{ $t("Schedule without the") }}
                    {{
                      childs.length > 1 ? $t("The Children") : $t("The Child")
                    }}
                  </h3>
                </v-col>
                <!-- <v-col cols="3" v-if="childs.length > 1">
                  <v-select
                    :items="childs"
                    item-text="name"
                    item-value="id"
                    v-model.trim="selectedChild"
                    solo
                    @change="getStudentScheduleData"
                  ></v-select>
                </v-col> -->
              </v-row>
              <v-row style="overflow-x: auto">
                <ScheduleComponent
                  ref="schedule"
                  :editable="false"
                  :classId="classId"
                  :academicId="academicId"
                  :vacations="true"
                  :HiddenNoData="true"
                  v-if="classId && academicId"
                ></ScheduleComponent>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Schedule -->

    <v-row>
      <v-col cols="12" sm="12" md="10">
        <v-row>
          <v-col cols="12" md="8">
            <div class="cart-sec" id="calendar">
              <v-row>
                <v-col cols="6" md="6">
                  <h3 class="calendar-title">{{ $t("Event Calendar") }}</h3>
                </v-col>
                <v-col cols="6" md="6">
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        class="calendar-date-select"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>{{ $t("Day") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>{{ $t("Week") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>{{ $t("Month") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>{{
                          $t("4 days")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <div class="main-color canlendar-date">{{ title }}</div>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-sheet height="350px">
                <v-calendar
                  :locale="currentAppLocale"
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :now="today"
                  :type="type"
                  :event-more="true"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @change="updateRange"
                ></v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card color="grey lighten-4" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-toolbar-title
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </v-card>
                </v-menu>
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <div class="date-sec date-secScroll">
        <p class="text-center dayName">
          {{ noteDate.day }}
          <span
            class="dayNote"
            v-if="
              day_notes &&
              not_in_active_year == 0 &&
              confirmed == 1 &&
              vacation == 0
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="dayNoteIcon">{{
                  "speaker_notes"
                }}</v-icon>
              </template>
              <span>{{ day_notes }}</span>
            </v-tooltip>
          </span>
        </p>
        <span class="prev">
          <v-icon large @click="nextPrevDay(noteDate.date, false)"
            >mdi-chevron-left</v-icon
          >
        </span>
        <v-text-field
          id="note-date2"
          class="dayNotes"
          v-model.trim="noteDate.date"
          autocomplete="off"
          solo
          dense
          @keydown.prevent
        ></v-text-field>
        <span class="next">
          <v-icon large @click="nextPrevDay(noteDate.date, true)"
            >mdi-chevron-right</v-icon
          >
        </span>
      </div>
      <v-col cols="12" sm="12" md="2" class="notification-section">
        <div class="date-sec">
          <p class="text-center dayName">
            {{ noteDate.day }}
            <span
              class="dayNote"
              v-if="
                day_notes &&
                not_in_active_year == 0 &&
                confirmed == 1 &&
                vacation == 0
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="dayNoteIcon">{{
                    "speaker_notes"
                  }}</v-icon>
                </template>
                <span>{{ day_notes }}</span>
              </v-tooltip>
            </span>
          </p>
          <span class="prev">
            <v-icon large @click="nextPrevDay(noteDate.date, false)"
              >mdi-chevron-left</v-icon
            >
          </span>
          <v-text-field
            id="note-date"
            class="dayNotes"
            v-model.trim="noteDate.date"
            autocomplete="off"
            solo
            dense
            @keydown.prevent
          ></v-text-field>
          <span class="next">
            <v-icon large @click="nextPrevDay(noteDate.date, true)"
              >mdi-chevron-right</v-icon
            >
          </span>
        </div>

        <v-alert
          color="#eaeefc"
          v-if="
            vacation == 1 ||
            confirmed == 0 ||
            not_in_active_year == 1 ||
            lessons_notes.length == 0
          "
          >{{ $t("no weekly plan added for this day") }}</v-alert
        >
        <div
          v-if="
            not_in_active_year == 0 &&
            confirmed == 1 &&
            lessons_notes.length > 0 &&
            vacation == 0
          "
        >
          <div v-for="(note, index) in lessons_notes" :key="index">
            <div class="notification">
              <p class="notification-note main-color">{{ note.subject }}</p>
              <v-alert
                color="#eaeefc"
                v-if="
                  !note.notes && !note.class_work_notes && !note.home_work_notes
                "
                >{{ $t("no weekly plan added for this lesson") }}</v-alert
              >
              <h4 id="note" v-if="note.notes">{{ $t("Notes") }}</h4>
              <p class="title" v-if="note.notes" style="white-space: normal">
                {{ note.notes }}
              </p>
              <h4 id="classwork" v-if="note.class_work_notes">
                {{ $t("Classwork") }}
              </h4>
              <p class="title" v-if="note.class_work_notes">
                {{ note.class_work_notes }}
              </p>
              <h4 id="Homework" v-if="note.home_work_notes">
                {{ $t("Homework") }}
              </h4>
              <p class="title" v-if="note.home_work_notes">
                {{ note.home_work_notes }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <div class="notificationScroll">
        <v-alert
          color="#eaeefc"
          v-if="
            vacation == 1 ||
            confirmed == 0 ||
            not_in_active_year == 1 ||
            lessons_notes.length == 0
          "
          >{{ $t("no weekly plan added for this day") }}</v-alert
        >
        <div
          v-if="
            not_in_active_year == 0 &&
            confirmed == 1 &&
            lessons_notes.length > 0 &&
            vacation == 0
          "
        >
          <div
            class="notifyItem"
            v-for="(note, index) in lessons_notes"
            :key="index"
          >
            <div class="notification">
              <p class="notification-note main-color">{{ note.subject }}</p>
              <v-alert
                color="#eaeefc"
                v-if="
                  !note.notes && !note.class_work_notes && !note.home_work_notes
                "
                >{{ $t("no weekly plan added for this lesson") }}</v-alert
              >
              <h4 id="note" v-if="note.notes">{{ $t("Notes") }}</h4>
              <p class="title" v-if="note.notes" style="white-space: normal">
                {{ note.notes }}
              </p>
              <h4 id="classwork" v-if="note.class_work_notes">
                {{ $t("Classwork") }}
              </h4>
              <p class="title" v-if="note.class_work_notes">
                {{ note.class_work_notes }}
              </p>
              <h4 id="Homework" v-if="note.home_work_notes">
                {{ $t("Homework") }}
              </h4>
              <p class="title" v-if="note.home_work_notes">
                {{ note.home_work_notes }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- socialmedia Component -->
      <SocialMedia :socialChannels="socialChannels" />
    </v-row>

    <HyperPayModelComponent
      v-if="HyperPayModel == true"
      :HyperPayModel="HyperPayModel"
      :actionUrl="actionUrl"
      v-on:childToParent="onChildClick"
      :shopperResultUrl="shopperResultUrl"
    ></HyperPayModelComponent>
  </div>
</template>

<script>
import { DashboardMixin } from "../../mixins/DashboardMixin";
import axios from "axios";
import ScheduleComponent from "../../components/Schedule/ScheduleComponent";
import DataTableComponent from "../../components/DataTableComponent";
// import onlinePaymentDialogComponent from "../../components/modals/PaymentCopayComponent";
import annoucementsDashbordComponent from "../../components/annoucementsDashbordComponent";
import HyperPayModelComponent from "../../components/modals/HyperPayModel";
import assignmentsOnParentDB from "../../components/dashboards/assignmentsOnParentDB";
import SocialMedia from "../../components/socialmediaComponent/socialmedia";

import ACL from "../../acl";
export default {
  mixins: [DashboardMixin],
  name: "ParentDashboard",
  components: {
    DataTableComponent,
    ScheduleComponent,
    // onlinePaymentDialogComponent,
    HyperPayModelComponent,
    annoucementsDashbordComponent,
    assignmentsOnParentDB,
    SocialMedia,
  },
  data() {
    return {
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      events: [],
      focus: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      start: null,
      end: null,
      filterMenu2: false,
      ChildCode: "",
      actionUrl: "",
      shopperResultUrl: "",
      HyperPayModel: false,
      resultCount: "",
      filter: {
        status: [0, 2],
        student_ids: [],
      },
      selectedChilds: [],
      isInvoice: false,
      pageInvoice: 1,
      circle: true,
      length: 0,
      totalVisible: 0,
      totalItems: 0,
      onlinePaymentdialog: false,
      noteDate: {
        date: "",
        day: "",
      },
      day_notes: "",
      vacation: 0,
      not_in_active_year: 0,
      confirmed: 0,
      lessons_notes: [],
      parentDashboard: true,
      page: 1,
      pageInvoices: 1,
      filterMenu: false,
      filterMenuInvoicess: false,
      studentInfo: [],
      table: {
        modal: "All Exam Results for parentDashboard",
        headers: [
          {
            text: this.$i18n.t("Exam Name"),
            value: "exam_name", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Subject"),
            value: "subject_name", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Qualifier"),
            value: "q_title", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Mark"),
            value: "student_mark/exam_mark",
            sortable: false,
          },
          {
            text: this.$i18n.t("Exam Date"),
            value: "exam_date", // not sortable item
            sortable: true,
          },
          {
            text: this.$i18n.t("Academic Period"),
            value: "academic_year_name", // not sortable item
            sortable: false,
          },
        ],
        title: "Exams",
        editedItem: {},
        apiEndPoints: {
          list: "students/getParentStudentMarks",
        },
        permissions: {
          list: "dashboard",
        },
      },

      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: this.$i18n.t("Expense"), value: "expenses" },
        { text: this.$i18n.t("Amount"), value: "fat" },
        { text: this.$i18n.t("Status"), value: "carbs" },
        { text: this.$i18n.t("date"), value: "date" },
      ],
      expenses: [],
      classId: null,
      academicId: null,
      childs: [],
      selectedChild: "",
      selectedChildFees: "",
      getTotalFeesPaid: "",
      childFees: "",
      search: "",
      statusItems: [
        { value: 1, name: "Paid" },
        { value: 2, name: "Partialy Paid" },
        { value: 0, name: "UnPaid" },
      ],
      invoicestable: {
        modal: "Parent Invoice",
        // path: "",
        headers: [
          {
            text: this.$i18n.t("Invoice Number"),
            value: "invoice_number",
            sortable: false,
          },
          {
            text: this.$i18n.t("student"),
            value: "student",
            sortable: false,
          },
          {
            text: this.$i18n.t("Amount"),
            value: "amount",
            sortable: false,
          },
          {
            text: this.$i18n.t("Amount After Discount"),
            value: "total_after_discount",
            sortable: false,
          },
          {
            text: this.$i18n.t("Status"),
            value: "payment_status",
            sortable: false,
          },
          {
            text: this.$i18n.t("Date"),
            value: "invoice_date",
            sortable: false,
          },

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: "Invoices",
        apiEndPoints: {
          list: "dashboard/parent/getChildrenInvoices",
        },

        permissions: {
          show: ACL.checkPermission("show-invoices"),
          dowenload: ACL.checkPermission("download-invoices"),
        },
        // filters: {
        //   ,
        // },
      },
      invoices: [],
      selectedChildFeesPaid: "",
      selectedStudent_id: "",
    };
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    totalPayment: function () {
      if (this.invoices && this.invoices.length != 0) {
        let total = 0;
        this.invoices.forEach((item) => {
          if (item.payment_status != 1) {
            total += item.amount;
          }
        });
        return total;
      } else {
        return 0;
      }
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  watch: {
    "noteDate.date": {
      handler() {
        this.getDayName();
        if (this.selectedChild) {
          this.getNotesDataForParent(this.noteDate.date, this.selectedChild);
        }
      },
    },
    pageInvoice: {
      handler() {
        this.getChildrenInvoices();
      },
      deep: true,
    },
    filterStatus: {
      handler() {
        this.pageInvoice = 1;
        this.getChildrenInvoices();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pageInvoice = 1;
        this.getChildrenInvoices();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.pageInvoice = 1;
        this.getChildrenInvoices();
      },
      deep: true,
    },
  },
  methods: {
    splitString(str) {
      if (str && str.length >= 14) {
        return str.slice(0, 15) + "..";
      } else {
        return str;
      }
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      axios
        .post(this.getApiUrl + "/school/getVacationsInPeriod", [], {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.events = response.data.data;
          }
        });
      this.start = start;
      this.end = end;
      // this.events = events;
    },
    MakeHimActive(index) {
      if (this.studentInfo[index].active != true) {
        this.studentInfo.forEach((student) => {
          student.active = false;
        });
        $(".kid").removeClass("active");
        this.studentInfo[index].active = true;
        $(".kid").eq(index).addClass("active");
        this.selectedStudent_id = this.studentInfo[index].student_id;
        this.filter.student_ids = [];
        this.filter.student_ids.push(this.selectedStudent_id);

        this.getNotesDataForParent(this.noteDate.date, this.selectedStudent_id);
        this.selectedChild = this.selectedStudent_id;
        this.getStudentScheduleData();
      } else {
        this.filter.student_ids = [];
        this.filter.student_ids.push(this.selectedStudent_id);
      }
    },

    getResultCount() {
      axios
        .get(this.getApiUrl + "/dashboard/parent/getResultCount", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.resultCount = response.data.data;
        });
    },
    PayInvoice(item) {
      // this.onlinePaymentdialog = true;
      this.ChildCode = item.student_code;
      this.selectedChildFees = item.id;
      this.isInvoice = true;
      this.childFees = item.total_after_discount;
      this.CreatePaymentForOnlinePayment();
    },
    CreatePaymentForOnlinePayment() {
      // axios
      //   .get(
      //     this.getApiUrl +
      //       "/dashboard/parent/createPaymentsForChildFees/" +
      //       selectedChildFees +
      //       "?isInvoice=" +
      //       isInvoice,

      //     {
      //       headers: {
      //         Authorization: "Bearer " + localStorage.token
      //       }
      //     }
      //   )
      //   .then(response => {
      //     let payment_id = response.data.data;
      //     this.shopperResultUrl = "/paymentRedirect/" + response.data.data;

      //     axios
      //       .post(
      //         this.getApiUrl + "/online_payment/PaymentGetCheckoutID",
      //         {
      //           amount: this.childFees,
      //           payment_id: payment_id
      //         },
      //         {
      //           headers: {
      //             Authorization: "Bearer " + localStorage.token
      //           }
      //         }
      //       )
      //       .then(response => {
      //         // window.location = response;
      //         let checkoutId = response.data.data.id;
      //         this.actionUrl =
      //           "https://oppwa.com/v1/paymentWidgets.js?checkoutId=" +
      //           checkoutId;
      // this.HyperPayModel = true;

      // this.$router.push({
      //   path:
      //     `/HyperPay?callbackUrl=` +
      //     this.shopperResultUrl +
      //     "&actionUrl=" +
      //     this.actionUrl
      // });
      //     });
      // });

      this.$router.push({
        path:
          `/HyperPayChooseBrand/` +
          this.ChildCode +
          "?isInvoice=" +
          this.isInvoice +
          "&invoiceId=" +
          this.selectedChildFees,
      });
    },

    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    onChildClick(paymentDialog) {
      this.onlinePaymentdialog = paymentDialog;
    },
    openPaymentDialog() {
      this.isInvoice = false;
      // this.onlinePaymentdialog = true;
      this.CreatePaymentForOnlinePayment(
        this.selectedChildFees,
        this.isInvoice
      );
    },
    getParentChilds() {
      axios
        .get(this.getApiUrl + "/schedules/getParentChildren", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.childs = res.data.data;
            this.selectedChild = this.childs[0].id;
            this.selectedChildFees = this.childs[0].id;
            this.selectedChildFeesPaid = this.childs[0].id;
            this.ChildCode = this.childs[0].code;
            this.getStudentScheduleData();
            this.getStudentFeesData();
            this.getTotalFeesForChildPaid();

            this.getNotesDataForParent(this.noteDate.date, this.selectedChild);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStudentScheduleData() {
      axios
        .get(
          this.getApiUrl +
            "/schedules/getStudentClassAndAcademicYear/" +
            this.selectedChild,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            this.classId = res.data.data.class_id;
            this.academicId = res.data.data.academicYearId;
            // this.$refs.schedule.getSchedules();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMyChildsInfo() {
      axios
        .get(this.getApiUrl + "/dashboard/parent", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.studentInfo = response.data.data.student_info;
          console.log(this.studentInfo);
          this.studentInfo.forEach((student) => {
            student.active = false;
          });
          this.studentInfo[0].active = true;
          this.selectedStudent_id = this.studentInfo[0].student_id;
          this.MakeHimActive(0);
        });
    },
    getStudentFeesData() {
      axios
        .get(
          this.getApiUrl +
            "/dashboard/parent/childFees/" +
            this.selectedChildFees,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.childFees = response.data.data;
          this.childs.forEach((child) => {
            if (child.id == this.selectedChildFees) {
              this.ChildCode = child.code;
            }
          });
        });
    },
    getTotalFeesForChildPaid() {
      axios
        .get(
          this.getApiUrl +
            "/dashboard/parent/getTotalFeesForChildPaid/" +
            this.selectedChildFeesPaid,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.getTotalFeesPaid = response.data.data;
        });
    },

    getChildrenInvoices() {
      axios
        .get(
          this.getApiUrl +
            "/dashboard/parent/getChildrenInvoices?page=" +
            this.pageInvoice +
            "&search=" +
            this.search,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.invoices = [];
          this.invoices = response.data.data.data;
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
        });
    },
    setPaginationParameters(response) {
      // this.page = response.data.data.current_page;
      // console.log("papa shreef > " + this.length);
      this.length = response.data.data.last_page;
      if (this.length > 0) this.totalVisible = 5;
    },
    childrenInvoice() {
      axios
        .get(
          this.getApiUrl +
            "/dashboard/parent/getChildrenInvoices" +
            this.selectedChild,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.invoices = response.data.data.data;
        });
    },
    printInvoice(id) {
      axios
        .get(this.getApiUrl + "/invoices/printPdfInvoice/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    getAttendance() {
      axios
        .get(
          this.getApiUrl +
            "/attendance/getSubjectDaysAttendanceOfstudentMobile",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
  },
  mounted() {
    // this.getAttendance();
    // console.log(this.invoicestable.permissions.dowenload);
    this.getResultCount();
    this.getParentChilds();
    this.getMyChildsInfo();
    this.getChildrenInvoices();
    let discount_appear = false;
    this.invoices.forEach((invoice) => {
      if (invoice.amount != invoice.total_after_discount) {
        discount_appear = true;
      }
    });
    if (discount_appear == false) {
      this.invoicestable.headers = this.invoicestable.headers.filter(function (
        item
      ) {
        return item.value != "total_after_discount";
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_dashboard.scss";

.title {
  margin-bottom: 0 !important;
}

.item {
  .item-content {
    .num {
      font-size: 1rem;
      margin: 0;
    }
  }
}

.item1 .item-content .value {
  color: $dashboard-red;
}
.item2 .item-content .value {
  color: $dashbaord-green;
}
.item3 .item-content .value {
  color: $dashboard-purple;
}
.item4 .item-content .value {
  color: $secondary-color;
}

.item .item-content .num {
  font-size: 0.75rem;
}

.item2 .item-content .num {
  color: #000000;
}
.item3 .item-content .num {
  color: #000000;
}
.item4 .item-content .num {
  color: #000000;
}

.main-background {
  background-color: $main-background;
}

.table {
  td {
    border: 0;
    font-size: 1rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.add-kids {
  background-color: $main-background;
  display: block;
  width: 100%;
  height: 234px;
  line-height: 200px;
  border-radius: 15px;
}

.kids {
  margin-bottom: 20px;
  .kid {
    border: 1px solid #dddddd;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    height: 140px;
    overflow: hidden;

    .student-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .student-name {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .student-grade,
    .student-class {
      color: #000000;
      margin-left: 10px;
    }
  }
  .kid.active {
    background-color: #d8e2ff;
    cursor: default;
  }
}
</style>
